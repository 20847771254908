import router from "@/router/index.js";
import serviceUser from "@/middleware/userController";
import serviceNotificao from "@/middleware/notificacoesDash";
import serviceTurma from "@/middleware/turmaController";
import serviceFornecedores from "@/middleware/fornecedores";
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";
import moment from "moment";
import store from "@/store/index.js";

function isTemplatePlan(recebido) {
  if (recebido == "Turma ainda não possui plano") {
    let anoAtual = moment().get("year");
    let mesAtual = moment().get("month") + 1;
    let anoFinal = moment()
      .add(1, "year")
      .get("year");
    return {
      anoFormatura: String(anoFinal),
      anoInicio: String(anoAtual),
      id: 0,
      itens: [],
      mesFormatura: String(mesAtual),
      mesInicio: String(mesAtual),
      qtdAluno: store.getters["aluno/getTurma"].numeroFormando,
      statusPlano: "FAZENDO",
      tipoCalculo: "VALOR_TOTAL",
      valorCotaFormando: 0,
      valorParcela: 0,
      valorTotal: 0
    };
  } else {
    return recebido;
  }
}

export default {
  deslogar({ commit }) {
    localStorage.clear();
    commit("removeTokenAuth");
    commit("removeTurma");
    commit("removeUsuario");
    router.push("/aluno/login");
  },
  REMOVE_PARTICIPANTE({ dispatch }, participanteId) {
    return new Promise((resolve, reject) => {
      serviceTurma
        .excluirParticipante(participanteId)
        .then(resp => {
          dispatch("REQUEST_ALUNOS_DA_TURMA");
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  SET_TURMA_ATIVA({ commit, getters }, id) {
    let turmaId = id;
    let userId = getters["getUser"].id;
    return new Promise((resolve, reject) => {
      serviceUser
        .setTurmaAtual(turmaId, userId)
        .then(() => {
          commit("setUserTurmaAtual", turmaId);
          resolve();
        })
        .catch(err => {
          commit("setLoaderContent", false);
          console.error("Erro SET_TURMA");
          console.error(err.data);
          reject();
        });
    });
  },
  REQUEST_SOLICITACOES_DE_PAGAMENTO({ commit, getters }) {
    return new Promise((resolve, reject) => {
      let turmaID = getters["getTurma"].id;
      serviceSolicitacoes
        .getSolitacoesDaTurma(turmaID)
        .then(resp => {
          commit("setSolicitacoesDePagamento", resp.data);
          return resolve();
        })
        .catch(err => {
          console.log(err);
          return reject();
        });
    });
  },
  REQUEST_FORNECEDORES({ commit, getters }) {
    return new Promise((resolve, reject) => {
      let idTurma = getters["getTurma"].id;
      serviceFornecedores
        .getFornecedorForTurma(idTurma)
        .then(resp => {
          commit("setFornecedores", resp.data);
          resolve();
        })
        .catch(err => {
          console.error(err.data);
          reject();
        });
    });
  },
  REQUEST_NOTIFICACOES({ commit }) {
    return new Promise((resolve, reject) => {
      serviceNotificao
        .getNotificacoes()
        .then(resp => {
          commit("setNotificacoes", resp.data);
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  },
  REQUEST_PLANO({ commit, getters }) {
    let turmaAtiva = getters["getUser"].turmaAtivaId;
    if (!turmaAtiva) return false;
    return new Promise((resolve, reject) => {
      if (!turmaAtiva) {
        commit("setLoaderContent", false);
        reject();
      }
      serviceTurma
        .getPlanoTurma(turmaAtiva)
        .then(dadosPlano => {
          let resultado = isTemplatePlan(dadosPlano.data);
          commit("setPlano", resultado);
          resolve();
        })
        .catch(err => {
          commit("setLoaderContent", false);
          let planTemplate = isTemplatePlan(err.data);
          commit("setPlano", planTemplate);
          reject();
          return false;
        });
    });
  },
  REQUEST_ALUNOS_DA_TURMA({ commit, getters }) {
    let turmaID = getters["getUser"].turmaAtivaId;

    return new Promise((resolve, reject) => {
      if (turmaID == null) reject("Não contem turma ativa");
      serviceTurma
        .getAlunos(turmaID)
        .then(resp => {
          commit("setAlunoDaTurma", resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          reject(err.data);
        });
    });
  },
  REQUEST_TURMA({ commit, getters }) {
    return new Promise((resolve, reject) => {
      let turmaAtiva = getters["getTurmaAtiva"];
      if (turmaAtiva == null) {
        //reject();
        commit("setLoaderContent", false);
        return false;
      }
      serviceTurma
        .getTurma(turmaAtiva)
        .then(resposta => {
          commit("setTurma", resposta.data);
          resolve();
        })
        .catch(err => {
          console.error(err);
          window.alert(err.data);
          commit("setLoaderContent", false);
          reject();
        });
    });
  },
  REQUEST_USER({ commit }) {
    return new Promise((resolve, reject) => {
      serviceUser
        .getUserData()
        .then(resp => {
          commit("setUserData", resp.data);
          resolve();
        })
        .catch(err => {
          console.error("Erro encontrado, user");
          console.error(err);
          commit("setLoaderContent", false);
          reject();
        });
    });
  },
  RELOAD_ALL({ dispatch, commit }) {
    commit("setLoaderContent", true);
    dispatch("REQUEST_USER").then(() => {
      dispatch("REQUEST_TURMA").then(() => {
        dispatch("REQUEST_ALUNOS_DA_TURMA").catch(err => {
          console.error(err);
        });
        dispatch("REQUEST_FORNECEDORES");
        dispatch("REQUEST_SOLICITACOES_DE_PAGAMENTO");
        dispatch("REQUEST_PLANO").then(() => {
          setTimeout(() => {
            commit("setLoaderContent", false);
          }, 1000);
        });
      });
    });
  }
};
