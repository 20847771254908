<template>
  <div class="cardContainTitleAndCloseIcon">
    <div>
      <div class="titleDefaultModal">
        Solicitação de pagamento para fornecedor
      </div>
    </div>
    <div v-if="!hiddenClose">
      <v-btn icon @click="close()">
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titulo: {
      default: "S/ TITULO",
      type: String,
      required: true
    },
    hiddenClose: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.titleDefaultModal {
  font-size: 18px;
  font-weight: 500;
  color: #37447e;
}

.cardContainTitleAndCloseIcon {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  & > *:nth-child(1) {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 0;
  }
}
</style>
