export default {
  setSolicitacoesDePagamento(state, payload) {
    state.solicitacoesDePagamento = payload;
  },
  setFornecedores(state, payload) {
    state.fornecedores = payload;
  },
  setNotificacaoView(state) {
    state.user.novasNotification = false;
  },
  setNotificacoes(state, payload) {
    state.notificacoes = payload;
  },
  setLoaderContent(state, payload) {
    state.loaderContent = payload;
  },
  setPlano(state, payload) {
    state.plano = payload;
  },
  setAlunoDaTurma(state, payload) {
    state.alunosDaTurma = payload;
  },
  setTurma(state, payload) {
    state.turma = payload;
  },
  setUserTurmaAtual(state, payload) {
    state.user.turmaAtivaId = payload;
  },
  setModalNovaTurma(state, payload) {
    state.modalNovaTurma = payload;
  },
  /* menu */
  setMini(state, pay) {
    state.menu.mini = pay;
  },
  setMiniToggle(state) {
    state.menu.mini = !state.menu.mini;
  },
  setMenu(state, pay) {
    state.menu.toggle = pay;
  },
  setMenuToggle(state) {
    state.menu.toggle = !state.menu.toggle;
  },
  setTokenAuth(state, payload) {
    state.auth.token = payload;
  },
  setExibirFCZ(state, payload) {
    state.user.exibirFCZ = payload;
  },
  setAceiteFCZ(state, payload) {
    state.user.aceiteFCZ = payload;
  },
  removeTokenAuth(state) {
    state.auth.token.tokenType = "";
    state.auth.token.accessToken = "";
    state.authStatus = false;
  },
  removeUsuario(state) {
    state.user = {
      nome: "",
      id: "",
      email: "",
      role: "",
      senha: "",
      situacaoCadastro: "",
      credit: 0,
      exibirFCZ: true,
      aceiteFCZ: false
    };
  },
  removeTurma(state) {
    state.turma = {};
    state.user.turmaAtiva = null;
  },
  setUserData(state, payload) {
    if (payload.role === "ADMIN" || payload.role === "AGENCIA") {
      const turmaAtiva = localStorage.getItem("APPDATURMA_TURMA_ATIVA");
      payload.turmaAtiva = turmaAtiva;
      payload.turmaAtivaId = turmaAtiva;
    }
    state.user = payload;
  }
};
