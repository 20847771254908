import axios from "axios";

export default {
  getUserData() {
    return axios.get("/v1/usuario/me");
  },
  setTurmaAtual(turmaId, usuarioId) {
    return axios.post("/v1/usuario/definir-turma-ativa", {
      turmaId: turmaId,
      usuarioId: usuarioId
    });
  },
  setUsuario(payload) {
    return axios.post("/v1/usuario", payload);
  },
  updateUserDados(id, payload) {
    return axios.post(`/v1/usuario/${id}`, payload);
  },
  getUsuarioViaHash(_hash) {
    return axios.get(`/v1/usuario/codigo-finalizacao-cadastro/${_hash}`);
  },
  uploadImagemPerfil(id, file) {
    return axios.post(`/v1/usuario/${id}/upload-foto`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  getUsuarioViaId(id) {
    return axios.get(`/v1/usuario/${id}`);
  },
  getUsuario(_email) {
    return axios.post("/v1/usuario/dadosusuario", {
      email: _email
    });
  },
  finalizaTutorial() {
    return axios.post("/v1/usuario/disable-tutorial");
  }
};
