import Vue from "vue";
import VueCurrencyFilter from "vue-currency-filter";
import money from "v-money";
import VueCurrencyInput from "vue-currency-input";
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: "BRL" }
};

Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(money, {
  precision: 2,
  decimal: ",",
  thousands: ".",
  masked: false
});

Vue.use(VueCurrencyFilter, [
  {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  },
  {
    name: "currencySemDecimal",
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  }
]);
