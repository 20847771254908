export default {
  getModal(state) {
    return state.modal;
  },
  getModules(state) {
    return state.modules;
  },
  getModuleFCZ(state) {
    return state.modules.fcz;
  }
};
