import axios from "axios";

export default {
  getFeatureFlag() {
    /* 
    featureflag: {
      fcz: true
    }
    */
    return axios.get("/v1/featureflag");
  }
};
