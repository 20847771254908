<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- ativador - form -->
      <v-text-field
        v-model="date"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" type="month" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        cancelar
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["label", "defaultValue"],
  data() {
    return {
      menu: false,
      date: ""
    };
  },
  watch: {
    defaultValue(response) {
      this.date = response; // deve ser receber YYYY/MM
    },
    date() {
      this.$emit("handleDate", this.date);
    }
  }
};
</script>
