import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#8F85FF",
        secondary: "#091133",
        accent: "#8c9eff",
        error: "#b71c1c",
        primaryDash: "#37447E",
        secondaryDash: "#7275FF",
        thirdDash: "#C2CFE0",
        textColorDash: "#5E5873"
      }
    }
  }
});

export default vuetify;
