export default {
  setModal(state, payload) {
    if (payload.toggle == true) {
      state.modal = payload;
    } else {
      state.modal = {
        toggle: false,
        mensagem: "",
        tipo: "informacao"
      };
    }
  },
  setModules(state, payload) {
    state.modules = payload;
  }
};
