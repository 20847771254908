//import store from "@/store/index.js";
import ServiceModules from "@/middleware/modules";

export default {
  REQUEST_MODULES({ commit }) {
    return new Promise((resolve, reject) => {
      ServiceModules.getFeatureFlag()
        .then(resposta => {
          commit("setModules", resposta.data);
          resolve();
        })
        .catch(err => {
          console.error(err);
          reject();
        });
    });
  }
};
