import { render, staticRenderFns } from "./devtools.vue?vue&type=template&id=35fce052&scoped=true&"
import script from "./devtools.vue?vue&type=script&lang=js&"
export * from "./devtools.vue?vue&type=script&lang=js&"
import style0 from "./devtools.vue?vue&type=style&index=0&id=35fce052&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fce052",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
