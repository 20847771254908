import axios from "axios";

export default {
  solicitarNovoPagamento(bin) {
    return axios.post("/v1/solicitacao-pagamento", {
      dataPagamento: bin.dataPagamento,
      descricao: bin.descricao,
      fornecedorId: bin.fornecedorId,
      id: null,
      turmaId: bin.turmaId,
      turmaIdDestino: bin.turmaIdDestino,
      tipo: bin.tipo,
      valor: bin.valor
    });
  },
  alterarSolitacaoPagamento(bin) {
    return axios.post("/v1/solicitacao-pagamento", {
      dataPagamento: bin.dataPagamento,
      descricao: bin.descricao,
      fornecedorId: bin.fornecedorId,
      id: bin.id,
      turmaId: bin.turmaId
    });
  },
  getSolitacoesDaTurma(turmaID) {
    return axios.get(`/v1/solicitacao-pagamento/turma/${turmaID}`);
  },
  getSolicitacaoPorID(solicitacaoId) {
    return axios.get(`/v1/solicitacao-pagamento/${solicitacaoId}`);
  },
  cancelarSolicitacao(solicitacaoId) {
    return axios.post(`/v1/solicitacao-pagamento/${solicitacaoId}/cancelar`);
  },
  /* removida para CANCELAR removerSolicitacao(solicitacaoId) {
    return axios.delete(`/v1/solicitacao-pagamento/${solicitacaoId}`);
  },*/
  getAnexoPorID(solicitacaoId, anexoID) {
    return axios.get(
      `/v1/solicitacao-pagamento/${solicitacaoId}/anexo/${anexoID}`
    );
  },
  enviarAnexo(solicitacaoId, formData) {
    return axios.post(
      `/v1/solicitacao-pagamento/${solicitacaoId}/anexo`,
      formData
    );
  },
  removerAnexo(solicitacaoId, anexoId) {
    return axios.delete(
      `/v1/solicitacao-pagamento/${solicitacaoId}/anexo/${anexoId}`
    );
  },
  setVotoSIM(solicitacaoId) {
    return axios.post(`/v1/solicitacao-pagamento/${solicitacaoId}/voto-sim`);
  },
  setVotoNAO(solicitacaoId) {
    return axios.post(`/v1/solicitacao-pagamento/${solicitacaoId}/voto-nao`);
  }
};
