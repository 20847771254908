export default {
  getSolicitacoesDePagamento(state) {
    return state.solicitacoesDePagamento;
  },
  getSolicitacaoDePagamentoById: state => id => {
    return state.solicitacoesDePagamento.find(todo => todo.id == id);
  },
  getFornecedores(state) {
    return state.fornecedores;
  },
  getNotificacoes(state) {
    return state.notificacoes;
  },
  getContentLoader(state) {
    return state.loaderContent;
  },
  getPlano(state) {
    return state.plano;
  },
  getStatusPlano(state) {
    return state.plano.statusPlano;
  },
  getPlanoFinalizado(state) {
    return state.plano.statusPlano != "FAZENDO";
  },
  getPermissaoTurmaAtiva(state) {
    return state.user.permissaoTurmaAtiva;
  },
  getAlunosDaTurma(state) {
    return state.alunosDaTurma;
  },
  getTurma(state) {
    return state.turma;
  },
  getTurmas(state) {
    return state.user.turmas;
  },
  getTurmaAtiva(state) {
    return state.user.turmaAtivaId;
  },
  getModalNovaTurma(state) {
    return state.modalNovaTurma;
  },
  getMenuToggle(state) {
    return state.menu.toggle;
  },
  getMiniToggle(state) {
    return state.menu.mini;
  },
  getToken(state) {
    return state.auth.token;
  },
  getAuthStatus(state) {
    return state.authStatus;
  },
  getUser(state) {
    return state.user;
  },
  getSaldoBilhetes(state) {
    return state.user.saldoBilhete;
  },
  getUserName(state) {
    return state.user.nome;
  },
  getUserMoney(state) {
    return state.user.credit;
  },
  getUserCadastroStatus(state) {
    return state.user.situacaoCadastro;
  },
  getExibirTutorial(state) {
    return state.user.exibirTutorial;
  },
  getUserRole(state) {
    return state.user.role;
  },
  userIsAdmin(state) {
    return state.user.role === "ADMIN" || state.user.role === "AGENCIA";
  },
  getPacote(state) {
    return {
      titulo: state.turma.pacoteTitulo ? state.turma.pacoteTitulo : null,
      id: state.turma.pacoteId ? state.turma.pacoteId : null,
      ativo: state.turma.pacoteId ? true : false
    };
  },
  getUserFCZ(state) {
    return {
      exibirFCZ: state.user.exibirFCZ,
      aceiteFCZ: state.user.aceiteFCZ
    };
  },
  getTurmaConfig(state) {
    if (state.turma.config) {
      return state.turma.config;
    } else {
      return {
        disableComissao: false,
        enableFCZ: false,
        qtdAdmin: 0
      };
    }
  }
};
