export default {
  name: "site",
  path: "/site",
  component: () =>
    import(/* webpackChunkName: "siteRoutes" */ "@/views/site/index.vue"),
  redirect: "/aluno/login",
  children: [
    {
      path: "home",
      name: "home",
      redirect: "/aluno/login",
      component: () =>
        import(
          /* webpackChunkName: "siteRoutes" */ "@/views/site/content/landingpage/index.vue"
        )
    },
    {
      path: "cadastro",
      component: () =>
        import(
          /* webpackChunkName: "siteRoutes" */ "@/views/site/content/cadastro/index.vue"
        ),
      props: true
    },
    {
      path: "cadastro/:id",
      component: () =>
        import(
          /* webpackChunkName: "siteRoutes" */ "@/views/site/content/cadastro/index.vue"
        ),
      props: true
    }
  ]
};
