<template>
  <div
    class="rootDev"
    :class="{ fixed: modeFix }"
    v-if="dev"
    @click="modeFix = !modeFix"
  >
    <div class="containInputs">
      <input type="text" v-model="pagamentoID" class="inputCustom" />
      <button
        style="background-color: white; color:black;"
        :disabled="!pagamentoID || pagamentoID === ''"
        @click="callPaymentMethod()"
      >
        PAGAR
      </button>
    </div>
    <div class="d-flex">
      <div class="px-1" v-if="modeFix">
        <v-icon size="15" color="white">mdi-coffee</v-icon>
      </div>
      <div class="pr-2">ViewPort:</div>
      <div class="font-weight-bold text-uppercase">
        <div v-if="$vuetify.breakpoint.xs">xs</div>
        <div v-if="$vuetify.breakpoint.sm">sm</div>
        <div v-if="$vuetify.breakpoint.md">md</div>
        <div v-if="$vuetify.breakpoint.lg">lg</div>
        <div v-if="$vuetify.breakpoint.xl">xl</div>
      </div>
    </div>
  </div>
</template>

<script>
import { devMode } from "@/middleware/devmode.js";
import Bilhetes from "@/middleware/bilhetes";
export default {
  data() {
    return {
      modeFix: false,
      pagamentoID: null
    };
  },
  methods: {
    callPaymentMethod() {
      const vendaID = this.pagamentoID;
      if (vendaID == null || vendaID === "") {
        return;
      }
      Bilhetes.pagarPix(vendaID)
        .then(resp => {
          this.$root.sucessoModal(resp.data);
        })
        .catch(err => {
          this.$root.errorModal(err.data?.message);
          console.error(err);
        });
    }
  },
  computed: {
    getUserTurmaAtiva() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    dev() {
      return devMode();
    }
  }
};
</script>

<style lang="scss" scoped>
.containInputs {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}
.inputCustom {
  padding: 0px 10px;
  background-color: white;
  margin: 0px;
}
.rootDev {
  padding: 5px;
  font-size: 10px;
  position: fixed;
  top: -40px;
  right: 5px;
  margin: 0 auto;
  opacity: 0.2;
  background-color: rgba(50, 50, 50, 1);
  color: white;
  transition: all 0.2s;
  z-index: 99999999;
  &.fixed {
    top: 0px;
    opacity: 1;
  }
  button {
    padding: 5px;
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  a {
    color: white;
    padding: 2px;
  }
  &:hover {
    top: 0px;
    opacity: 1;
  }
}
</style>
