export default {
  setModal(state, payload) {
    if (payload.toggle == true) {
      state.modal.toggle = true;
      state.modal.mensagem = payload.mensagem;
      state.modal.tipo = payload.tipo;
    } else {
      state.modal.toggle = false;
      state.modal.mensagem = "";
      state.modal.tipo = "info";
    }
  }
};
