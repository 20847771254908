import axios from "axios";

const Bilhetes = {
  verificaFilaPix(vendaId) {
    return axios.get(`/v1/vendabilhete/with-pix/${vendaId}`);
  },
  verificarCompra(vendaID) {
    return axios.get(`/v1/vendabilhete/${vendaID}`);
  },
  paginarBilhetes(filtros) {
    return axios.post("/v1/vendabilhete/vendedor/pagination", {
      descending: true,
      direction: "ASC",
      filtros: {
        cpfComprador: filtros.cpfComprador,
        id: filtros.id,
        nomeComprador: filtros.nomeComprador
      },
      pageNo: filtros.page,
      pageSize: filtros.size,
      sortBy: ""
    });
  },

  /**
   * Retorna o concurso atual ativo
   *
   * @returns {Promise}
   */
  getConcurso() {
    return axios.get(`/v1/concurso/concurso-atual`);
  },
  getVenda(saleId) {
    return axios.get(`/v1/vendabilhete/${saleId}`);
  },
  getBilhetesPorVendaId(saleId) {
    return axios.get(`/v1/vendabilhete/bilhetes/${saleId}`);
  },
  gerarPix(payload) {
    return axios.post("/v1/vendabilhete/gerar-pix", {
      concursoId: payload.concursoId,
      cpfComprador: payload.cpfComprador,
      cpfVendedor: payload.cpfVendedor,
      emailComprador: payload.emailComprador,
      id: payload.id,
      nomeComprador: payload.nomeComprador,
      qtdBilhete: payload.qtdBilhete,
      status: payload.status, //PAGO
      telefoneComprador: payload.telefoneComprador,
      valorTotal: payload.valorTotal
    });
  },
  pagarPix(vendaId) {
    if (process.env.NODE_ENV === "development") {
      return axios.get(`/v1/vendabilhete/mock-liquidar-venda/${vendaId}`);
    } else {
      return Error("Não autorizado");
    }
  }
};

export default Bilhetes;
