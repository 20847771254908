<template>
  <v-app data-app>
    <v-main>
      <vModal />
      <vDev v-if="main == 'development'" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import vDev from "@/components/devtools.vue";
import vModal from "@/components/dash/modalCustom.vue";
export default {
  name: "App",
  components: {
    vDev,
    vModal
  },
  computed: {
    main() {
      return process.env.NODE_ENV;
    }
  }
};
</script>

<style lang="scss">
@import "./scss/fonts.scss";
@import "./scss/global.scss";
$scrollBar: $primary;
$scrollFundo: rgba(235, 235, 255, 5);
* {
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 8px; // width of the entire scrollbar
    opacity: 0.5;
  }

  &::-webkit-scrollbar-track {
    background: $scrollFundo; // color of the tracking area
    cursor: col-resize;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollBar; // color of the scroll thumb
    border-radius: 20px; // roundness of the scroll thumb
    border: 2px solid $scrollFundo; //creates padding around scroll thumb
  }
}

body {
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  text-rendering: optimizeLegibility;
}
#app {
  text-rendering: optimizeLegibility;
}
</style>
