import { register } from "register-service-worker";
import PushService from "@/middleware/pushNotification";

//MODIFICAR key essa é da FMG
const applicationServerPublicKey =
  "BOzMAmmqqRGj4ae9xiBsfKIpOmwd4KUFE8BlwI7T81VA-T3Y8x43jLjlqQWwez23_R5ZCxfiN6t1PERUK0a945I";

let swRegistration = null;

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateSubscriptionOnServer(subscription) {
  if (subscription) {
    let text = JSON.stringify(subscription);
    // chave console.log(text);
    let obj = JSON.parse(text);
    PushService.subscribe({
      endpoint: obj.endpoint,
      p256dh: obj.keys["p256dh"],
      auth: obj.keys["auth"]
    });
  }
}

export function ativarPushNotification() {
  if (swRegistration) {
    //console.log("Chamou o subscribe");
    if (Notification.permission === "denied") {
      console.log("Usuário não permitiu o push notification");
      return;
    }
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
    swRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
      .then(function(subscription) {
        //console.log("User is subscribed:", subscription);
        updateSubscriptionOnServer(subscription);
      })
      .catch(function(err) {
        console.log("Failed to subscribe the user: ", err);
      });
  } else {
    setTimeout(ativarPushNotification, 5000);
  }
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("Service worker is active.");
    },
    registered(registration) {
      console.log("Service Worker is registered", registration);
      swRegistration = registration;
      ativarPushNotification();
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
