export default {
  modal: {
    toggle: false,
    mensagem: "",
    tipo: "informacao"
  },
  modules: {
    fcz: false
  }
};
