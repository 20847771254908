import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";
import moduloAluno from "./aluno/index.js";
import moduloSite from "./site/index.js";
import moduloGlobal from "./global/index.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    aluno: moduloAluno,
    site: moduloSite,
    global: moduloGlobal
  }
  //plugins: [createPersistedState()]
});
