export default {
  notificacoes: [],
  menu: {
    toggle: true,
    mini: false
  },
  loaderContent: true,
  authStatus: false,
  auth: {
    token: {
      tokenType: undefined,
      accessToken: undefined
    }
  },
  turma: {},
  alunosDaTurma: [],
  plano: {},
  modalNovaTurma: false,
  fornecedores: [],
  solicitacoesDePagamento: [],
  user: {
    exibirTutorial: true,
    nome: "",
    id: "",
    email: "",
    role: "",
    senha: "",
    enderecoCompleto: {
      cep: "teste"
    },
    situacaoCadastro: "",
    credit: 0,
    exibirFCZ: true,
    aceiteFCZ: false
  }
};
