import axios from "axios";
export default {
  novoFornecedor(include) {
    return axios.post("/v1/fornecedor", include);
  },
  getFornecedorId(id) {
    return axios.get(`/v1/fornecedor/${id}`);
  },
  getFornecedorForTurma(id) {
    return axios.get(`/v1/fornecedor/turma/${id}`);
  },
  removeFornecedor(id) {
    return axios.delete(`/v1/fornecedor/${id}`);
  }
};
