//resource
import Vue from "vue";
import vDateComp from "@/components/dateMonth.vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import vTitle from "@/components/tituloPadrao";
import vModalTitulo from "@/components/vModalTitulo";
import vModalHeader from "@/components/modal/vModalHeader.vue";

Vue.component("vTitulo", vTitle);
Vue.component("vModalTitulo", vModalTitulo);
Vue.component("vDateMonth", vDateComp);
Vue.component("vModalHeader", vModalHeader);

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.filter("dataFormate", function(data) {
  if (data) {
    let processoData = moment(data).isValid();
    if (processoData) return moment(data).format("DD/MM/YYYY");
    else return "Data inválida";
  } else {
    return "Data inválida";
  }
});
